<template>
  <v-col cols="12">
    <Loader
      v-if="loading"
      :key="generateUniqueId()"
      :loader-state="loading"
    ></Loader>

    <v-card>
      <v-card-title>Paramètres de metadonnées</v-card-title>

      <v-card-text>
        <div class="global-actions">
          <router-link :to="{ name: 'metadataParameterCreate' }">
            <v-btn
              small
              color="primary"
              class="mt-5"
            >
              Ajouter des paramètres
            </v-btn>
          </router-link>
        </div>
      </v-card-text>

      <div class="clear"></div>

      <v-simple-table>
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-uppercase">
                Page
              </th>
              <th class="text-center text-uppercase">
                Route
              </th>
              <th class="text-center text-uppercase">
                Actions
              </th>
            </tr>
          </thead>
          <tbody v-if="metadataParameters.length">
            <tr
              v-for="(metadataParameter, metadataParameterIndex) in metadataParameters"
              :key="'m' + metadataParameterIndex"
            >
              <td class="font-weight-bold">
                {{ metadataParameter.page ? metadataParameter.page : '-' }}
              </td>
              <td class="text-center">
                {{ metadataParameter.route ? metadataParameter.route : '-' }}
              </td>
              <td class="text-center">
                <v-tooltip top>
                  <template #activator="{ on, attrs }">
                    <v-btn
                      color="warning"
                      fab
                      dark
                      x-small
                      v-bind="attrs"
                      :to="{ name: 'metadataParameterDetails', params: { metadataParameterRoute: metadataParameter.route }}"
                    >
                      <v-icon dark>
                        {{ icons.mdiEye }}
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>Consulter</span>
                </v-tooltip>

                <v-tooltip top>
                  <template #activator="{ on, attrs }">
                    <v-btn
                      class="ml-2"
                      color="error"
                      fab
                      dark
                      x-small
                      v-bind="attrs"
                      @click="openDeleteMetadataParameterDialog(metadataParameter)"
                    >
                      <v-icon dark>
                        {{ icons.mdiTrashCan }}
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>Supprimer</span>
                </v-tooltip>
              </td>
            </tr>
          </tbody>
          <tbody v-if="!metadataParameters.length">
            <tr>
              <td>
                <span v-if="loading === false">Aucun éléments.</span>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>

      <DeleteMetadataParameterDialog
        :key="Date.now()"
        :delete-metadata-parameter-dialog-state="deleteMetadataParameterDialogState"
        :metadata-parameter="metadataParameterToDelete"
        @closeDeleteMetadataParameterDialog="closeDeleteMetadataParameterDialog($event)"
      ></DeleteMetadataParameterDialog>
    </v-card>
  </v-col>
</template>

<script>
import { mdiEye, mdiTrashCan } from '@mdi/js'
import Vue from 'vue'
import DeleteMetadataParameterDialog from '@/components/Dialog/SeoDialog/MetadataParameter/DeleteMetadataParameterDialog'
import config from '../../../config'
import Loader from '@/components/Common/Loader'

export default {
  components: {
    DeleteMetadataParameterDialog,
    Loader
  },
  data: () => ({
    metadataParameters: [],
    metadataParameterToDelete: null,
    deleteMetadataParameterDialogState: false,
    loading: true,
    icons: {
      mdiEye,
      mdiTrashCan
    }
  }),
  created () {
    this.getMetadataParameters()
  },
  methods: {
    getMetadataParameters () {
      this.loading = true

      Vue.prototype.$http
        .get(config.apiUrl + '/api/seo/metadata-parameters', {
          headers: {
            Authorization: 'Bearer ' + this.$store.getters.getToken
          }
        })
        .then(response => {
          if (response.status === 200) {
            const data = response.data

            if (data) {
              this.metadataParameters = data
            }
          }

          this.loading = false
        })
        .catch(e => {
          const response = e.response

          if (response.status === 400 || response.status === 404) {
            const data = response.data
            this.errors = data.violations
          }

          this.valid = true
          this.loading = false
        })
    },
    openDeleteMetadataParameterDialog (metadataParameter) {
      this.deleteMetadataParameterDialogState = true
      this.metadataParameterToDelete = metadataParameter
    },
    closeDeleteMetadataParameterDialog (deleteState) {
      if (deleteState === true) {
        this.metadataParameters = this.metadataParameters.filter(element => element.id !== this.metadataParameterToDelete.id)
      }

      this.deleteMetadataParameterDialogState = false
    }
  }
}
</script>
